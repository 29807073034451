import React from 'react';
import './Footer.css'; 

function Footer() {

  return (
    <footer className="footer">
      <div className="container">
        <div className="footer-column">
          <div className="footer-content">
            <div className='footer-content-l'>
              <div className='menu-link'>
                <span className="text-14-b tbg-withe">
                  <span className="cc-link-nv">Servicios</span>
                </span>
              </div>
              <div className='menu-link'>
                <span className="text-12-s tbg-withe">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#fff" className="bi bi-caret-right-fill" viewBox="0 0 16 16">
                    <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z"/>
                  </svg>Desarrollo de Software
                </span>
              </div>
              <div className='menu-link'>
                <span className="text-12-s tbg-withe">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#fff" className="bi bi-caret-right-fill" viewBox="0 0 16 16">
                    <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z"/>
                  </svg>App's Web y Moviles
                </span>
              </div>
              <div className='menu-link'>
                <span className="text-12-s tbg-withe">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#fff" className="bi bi-caret-right-fill" viewBox="0 0 16 16">
                    <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z"/>
                  </svg>Consultoria IT
                </span>
              </div>
              <div className='menu-link'>
                <span className="text-12-s tbg-withe">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#fff" className="bi bi-caret-right-fill" viewBox="0 0 16 16">
                    <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z"/>
                  </svg>Soluciones empresariales de Infraestructura y TI
                </span>
              </div>
            </div>
            <div className='footer-content-r'>
              <h4 className='title-h1-b tbg-withe'>Siguenos&nbsp;&nbsp;</h4> 
              <div className='menu-media'>
                <span className="text-14-s tbg-withe"><a className='link-back-w' href='https://www.facebook.com/viwhiteyyo' alt="Facebook">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#fff" className="bi bi-facebook" viewBox="0 0 16 16">
                    <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z"/>
                  </svg></a>
                </span>
              </div>
              <span className="text-10-s tbg-withe">Antes&nbsp;Viwhite (Nos encontramos en cambio de marca)</span>
            </div> 
          </div>
          <div className="footer-pie">
            <div className='footer-pie-l'>
              <span className="text-14-s tbg-withe">Chpriv&nbsp;&copy;&nbsp; 2024 </span>
            </div>
            <div className='footer-pie-r'>
            <span className="text-10-s tbg-withe">Oficina principal:&nbsp;Bello - Antioquia, Cr. 61 CL 51-16 INT 303</span>
            <span className="text-10-s tbg-withe">Correo:&nbsp;info@chpriv.com</span>
            </div> 
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;

