import React, { Suspense } from 'react'; 
import { createRoot } from 'react-dom/client';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import './index.css';
import Loading from './components/Loading'; 
import Footer from './partscomp/Footer';
import reportWebVitals from './reportWebVitals';
import { AuthProvider } from './access-in/AuthContext'; 

const App = React.lazy(() => import('./App'));
const history = createBrowserHistory();
const rootElement = document.getElementById('root');
                                                                                                  
createRoot(rootElement).render(   
  <React.StrictMode>                     
    <AuthProvider>                                  
    <Suspense fallback={<Loading />}> 
      <Router history={history}>                        
        <App /> 
        <Footer />
      </Router>  
    </Suspense>                
    </AuthProvider>  
  </React.StrictMode>,  
  rootElement
);
   
reportWebVitals(); 
   