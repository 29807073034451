import React from 'react';
import './Loading.css'; 

function Loading() {
  return ( 
      <div className='Ch-loadding-ppl'>
        <img src="https://cdn.chpriv.com/images/pred.png" alt="Loading Icon" className="Ch-loading-icon" />
      </div> 
  );
}

export default Loading;